import React from 'react'
import AsyncCreatable from 'react-select/async-creatable'

const TAG_MAX_CHARS = 50

class VentionTag extends React.Component {
  constructor(props) {
    super(props)
    this.state = { tags: this.props.tags, inputValue: '' }
    this.updateInput = this.updateInput.bind(this)
    this.inputName = this.props.inputName || 'tags'
    this.fetchServices = function () {
      var wait_timer = null,
        waiting = null,
        processing = null,
        fetch_services
      fetch_services = {
        fetchNow: function (input, callback) {
          window
            .fetch(`/api/v1/tags?query=${input}`)
            .then(response => {
              return response.json()
            })
            .then(json => {
              callback(
                json.map(entry => {
                  return { value: entry, label: entry }
                })
              )
              processing = undefined
            })
        },
        processWaiting: function () {
          wait_timer = null
          if (undefined == waiting) {
            return
          }
          if (undefined != processing) {
            wait_timer = window.setTimeout(fetch_services.processWaiting, 300)
            return
          }
          processing = waiting
          waiting = null
          fetch_services.fetchNow(processing.input, processing.callback)
        },
        getOptions: function (input, callback) {
          if (undefined != wait_timer) {
            window.clearTimeout(wait_timer)
          }
          waiting = { input: input, callback: callback }
          wait_timer = window.setTimeout(fetch_services.processWaiting, 300)
        },
      }
      return fetch_services
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ tags: nextProps.tags })
  }
  updateInput(maybeTags) {
    const tags = maybeTags || []
    const removedEmptyTag = tags
      .map(selection => selection.value.trim().replace(/[^\w\s-]/gi, ''))
      .filter(tag => tag.replace(/[^A-Za-z0-9]/g, '') !== '')
    var uniquTagList = []
    $.each(removedEmptyTag, function (i, el) {
      if ($.inArray(el, uniquTagList) === -1) uniquTagList.push(el)
    })
    this.setState({ tags: uniquTagList, inputValue: '' })
    this.props.update && this.props.update({ target: 'tags', payload: uniquTagList })
  }

  handleInputChange(textInput, { action }) {
    if (textInput && action === 'input-change' && textInput.includes(',')) {
      const bulkKeys = textInput.split(',').map(tag => tag.trim().substr(0, TAG_MAX_CHARS))
      const allTags = [...this.state.tags, ...bulkKeys]
      this.updateInput(allTags.map(tag => ({ value: tag, label: tag })).filter(tag => tag.value))
      return
    }
    this.setState({ inputValue: textInput.substr(0, TAG_MAX_CHARS) })
  }

  render() {
    return (
      <>
        {this.props.label && <label>{this.props.label}</label>}
        <AsyncCreatable
          className={this.props.className}
          classNamePrefix='select'
          name={this.inputName}
          inputProps={{ autoComplete: 'off' }}
          allowCreateWhileLoading={true}
          isMulti
          inputValue={this.state.inputValue}
          value={this.state.tags.map(tag => {
            return { value: tag, label: tag }
          })}
          loadOptions={this.fetchServices().getOptions}
          onChange={this.updateInput}
          onInputChange={this.handleInputChange.bind(this)}
          placeholder={'Start typing...'}
          onBlur={this.props.onBlur}
          styles={{
            dropdownIndicator: base => ({
              ...base,
              visibility: 'hidden',
            }),
            indicatorsContainer: (base, state) => ({
              ...base,
              appearance: 'menulist',
              '&:hover': state.isFocused ? { color: '#666' } : { color: '#666' },
              color: state.isFocused ? '#666' : '#666',
            }),
          }}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
          }}
        />
      </>
    )
  }
}
export default VentionTag
